import $ from 'jquery';

import { cookieGetItem } from '../cookie';
import { USER_INFO } from '../constants';

export const init = () => {
  const userInfo = cookieGetItem(USER_INFO);
  const classEntry = $('.class_entry');
  const trailCourseEntry = $('.trail_course_entry');
  const loginAndRegister = $('.login_register');
  if (userInfo) {
    classEntry.removeClass('d-none');
    trailCourseEntry.addClass('d-none');
    loginAndRegister.addClass('d-none');
  } else {
    trailCourseEntry.removeClass('d-none');
    loginAndRegister.removeClass('d-none');
    classEntry.addClass('d-none');
  }
};
